var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c("div", [
                _vm.ok
                  ? _c(
                      "div",
                      [
                        _c("validation-observer", {
                          ref: "observer",
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "default",
                                fn: function ({ invalid, handleSubmit }) {
                                  return [
                                    _c(
                                      "b-form",
                                      {
                                        on: {
                                          submit: function ($event) {
                                            $event.preventDefault()
                                          },
                                        },
                                      },
                                      [
                                        _c(
                                          "div",
                                          { staticClass: "mt-2" },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header: "Dati Generali",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-input", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].display.label,
                                                              vid: "display",
                                                              label:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ].display.label,
                                                              placeholder:
                                                                "Inserisci un codice",
                                                              rules:
                                                                _vm.getRules(
                                                                  "display"
                                                                ),
                                                              maxlength: "3",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].display,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "display",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].display",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-textarea", {
                                                            attrs: {
                                                              vid: "title",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].title.label,
                                                              label:
                                                                "Descrizione",
                                                              placeholder:
                                                                "Inserisci una descrizione",
                                                              rules: {
                                                                required: true,
                                                              },
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].title,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "title",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].title",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-select", {
                                                            attrs: {
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].is_anticipated
                                                                .label,
                                                              vid: "is_anticipated",
                                                              label: "Acconto",
                                                              options:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ].is_anticipated
                                                                  .options,
                                                              rules:
                                                                _vm.getRules(
                                                                  "is_anticipated"
                                                                ),
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .is_anticipated,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "is_anticipated",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].is_anticipated",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-card",
                                              {
                                                staticClass: "mt-1",
                                                attrs: {
                                                  header:
                                                    "Codici Di Riferimento",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("b-row", [
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-radio", {
                                                            attrs: {
                                                              vid: "status_broker",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].status_broker
                                                                .label,
                                                              label:
                                                                "Codice produttore",
                                                              options:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ].status_broker
                                                                  .options,
                                                              rules:
                                                                _vm.getRules(
                                                                  "status_broker"
                                                                ),
                                                              stacked: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ].status_broker,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "status_broker",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].status_broker",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-radio", {
                                                            attrs: {
                                                              vid: "status_insurer",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].status_insurer
                                                                .label,
                                                              label:
                                                                "Codice Compagnia",
                                                              options:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ].status_insurer
                                                                  .options,
                                                              rules:
                                                                _vm.getRules(
                                                                  "status_insurer"
                                                                ),
                                                              stacked: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .status_insurer,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "status_insurer",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].status_insurer",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                      _vm._v(" "),
                                                      _c(
                                                        "div",
                                                        {
                                                          staticClass:
                                                            "form-group col-md-3",
                                                        },
                                                        [
                                                          _c("base-radio", {
                                                            attrs: {
                                                              vid: "status_registry",
                                                              name: _vm.beForm[
                                                                _vm.rep
                                                              ].status_registry
                                                                .label,
                                                              label:
                                                                "Anagrafica Cliente",
                                                              options:
                                                                _vm.beForm[
                                                                  _vm.rep
                                                                ]
                                                                  .status_registry
                                                                  .options,
                                                              rules:
                                                                _vm.getRules(
                                                                  "status_registry"
                                                                ),
                                                              stacked: "",
                                                            },
                                                            model: {
                                                              value:
                                                                _vm.form[
                                                                  _vm.rep
                                                                ]
                                                                  .status_registry,
                                                              callback:
                                                                function ($$v) {
                                                                  _vm.$set(
                                                                    _vm.form[
                                                                      _vm.rep
                                                                    ],
                                                                    "status_registry",
                                                                    $$v
                                                                  )
                                                                },
                                                              expression:
                                                                "form[rep].status_registry",
                                                            },
                                                          }),
                                                        ],
                                                        1
                                                      ),
                                                    ]),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm._l(
                                              _vm.treasuries_config,
                                              function (config, index) {
                                                return _c(
                                                  "b-card",
                                                  {
                                                    key: `config${index}`,
                                                    staticClass: "mt-1",
                                                    attrs: {
                                                      header: config.header,
                                                      "header-tag": "header",
                                                    },
                                                  },
                                                  [
                                                    _c(
                                                      "b-card-text",
                                                      [
                                                        _c(
                                                          "b-row",
                                                          [
                                                            _vm._l(
                                                              _vm.getFilteredTreasuries(
                                                                _vm.treasuries,
                                                                config.is_system,
                                                                config.is_abstract,
                                                                config.is_agency
                                                              ),
                                                              function (
                                                                treasury,
                                                                index
                                                              ) {
                                                                return _c(
                                                                  "div",
                                                                  {
                                                                    key: index,
                                                                    staticClass:
                                                                      "form-group col-md-3",
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "base-radio",
                                                                      {
                                                                        attrs: {
                                                                          vid: `${config.type}-${treasury.value}`,
                                                                          name: `${config.type}-${treasury.value}`,
                                                                          label:
                                                                            treasury.text,
                                                                          options:
                                                                            _vm
                                                                              .beForm[
                                                                              "various_accounting"
                                                                            ]
                                                                              .value_type
                                                                              .options,
                                                                          rules:
                                                                            _vm.getRules(
                                                                              "value_type"
                                                                            ),
                                                                          stacked:
                                                                            "",
                                                                        },
                                                                        model: {
                                                                          value:
                                                                            treasury.option,
                                                                          callback:
                                                                            function (
                                                                              $$v
                                                                            ) {
                                                                              _vm.$set(
                                                                                treasury,
                                                                                "option",
                                                                                $$v
                                                                              )
                                                                            },
                                                                          expression:
                                                                            "treasury.option",
                                                                        },
                                                                      }
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              }
                                                            ),
                                                            _vm._v(" "),
                                                            !_vm.getFilteredTreasuries(
                                                              _vm.treasuries,
                                                              config.is_system,
                                                              config.is_abstract,
                                                              config.is_agency
                                                            ).length
                                                              ? _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "mx-3",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                      Nessuna\n                    "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          2
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                )
                                              }
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "show",
                                                rawName: "v-show",
                                                value:
                                                  _vm.customInputs[_vm.rep]
                                                    .length,
                                                expression:
                                                  "customInputs[rep].length",
                                              },
                                            ],
                                            staticClass: "mt-2",
                                          },
                                          [
                                            _c(
                                              "b-card",
                                              {
                                                attrs: {
                                                  header:
                                                    "Attributi personalizzati",
                                                  "header-tag": "header",
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-card-text",
                                                  [
                                                    _c("custom-inputs", {
                                                      attrs: {
                                                        customInputs:
                                                          _vm.customInputs[
                                                            _vm.rep
                                                          ],
                                                        beForm:
                                                          _vm.beForm[_vm.rep],
                                                        beRules:
                                                          _vm.beRules[_vm.rep],
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.form[_vm.rep],
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.form,
                                                            _vm.rep,
                                                            $$v
                                                          )
                                                        },
                                                        expression: "form[rep]",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          { staticClass: "py-2 mb-4" },
                                          [
                                            _c(
                                              "b-button",
                                              {
                                                attrs: {
                                                  type: "button",
                                                  disabled: invalid,
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return handleSubmit(
                                                      _vm.onSubmit
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                Salva\n              "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "b-button",
                                              {
                                                staticClass: "float-right",
                                                attrs: {
                                                  variant: "lisaweb",
                                                  size: "sm",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.$router.back()
                                                  },
                                                },
                                              },
                                              [
                                                _c(
                                                  "b-icon-chevron-double-left",
                                                  {
                                                    attrs: {
                                                      "font-scale": "0.9",
                                                    },
                                                  }
                                                ),
                                                _vm._v("Torna indietro"),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            510306730
                          ),
                        }),
                      ],
                      1
                    )
                  : _c("div", [
                      _vm._v(
                        "\n        " + _vm._s(_vm.errorMessage) + "\n      "
                      ),
                    ]),
              ])
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }