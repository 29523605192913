var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-overlay",
        {
          attrs: { center: "", show: _vm.isLoading, rounded: "sm" },
          scopedSlots: _vm._u([
            {
              key: "overlay",
              fn: function () {
                return [
                  _c(
                    "div",
                    { staticClass: "text-center mt-5" },
                    [
                      _c("base-icon", {
                        attrs: { name: "loading", width: "35", height: "35" },
                      }),
                      _vm._v(" "),
                      _c("p", { attrs: { id: "cancel-label" } }, [
                        _vm._v("Operazione in corso..."),
                      ]),
                    ],
                    1
                  ),
                ]
              },
              proxy: true,
            },
          ]),
        },
        [
          !_vm.isLoading
            ? _c(
                "div",
                [
                  _c(
                    "b-form",
                    {
                      on: {
                        submit: function ($event) {
                          $event.preventDefault()
                        },
                      },
                    },
                    [
                      _vm._l(_vm.detailFields, function (item, index) {
                        return _c("details-card", {
                          key: index,
                          attrs: {
                            header: item.header,
                            edit: item.edit || false,
                            fields: item.fields,
                            beForm: _vm.beForm,
                            data: _vm.book_entry_data,
                            repository: item.repository,
                            labels: _vm.labels,
                          },
                        })
                      }),
                      _vm._v(" "),
                      _vm._l(_vm.registryDetailsFields, function (item, i) {
                        return _c("book-entry-registry-details-card", {
                          key: i,
                          attrs: {
                            header: item.header,
                            fields: item.fields,
                            data: _vm.book_entry_data,
                            repository: item.repository,
                            labels: _vm.labels,
                            relation: item.relation,
                          },
                        })
                      }),
                      _vm._v(" "),
                      _c("book-entry-various-accounting-details", {
                        attrs: {
                          header: "Dettaglio",
                          data: _vm.book_entry_data,
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "b-card",
                        {
                          staticClass: "mt-1",
                          attrs: { header: "true", "header-tag": "header" },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex justify-content-between align-items-center",
                              attrs: { slot: "header" },
                              slot: "header",
                            },
                            [
                              _c(
                                "p",
                                {
                                  staticClass:
                                    "mb-0 d-inline-block align-middle",
                                },
                                [_c("b", [_vm._v("Forme di Pagamento")])]
                              ),
                              _vm._v(" "),
                              _c(
                                "b-button",
                                {
                                  attrs: {
                                    type: "button",
                                    variant: "lisaweb",
                                    title: "Vai alla sezione di modifica",
                                  },
                                  on: { click: _vm.onEdit },
                                },
                                [_vm._v("Modifica\n            ")]
                              ),
                            ],
                            1
                          ),
                        ]
                      ),
                    ],
                    2
                  ),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }