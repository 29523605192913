var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mt-1" },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { align: "left" } },
            [
              _c(
                "b-button-group",
                {
                  directives: [
                    {
                      name: "b-toggle",
                      rawName: "v-b-toggle:collapse-1",
                      arg: "collapse-1",
                    },
                  ],
                  staticClass: "my-2 filter-button-group",
                  attrs: { title: _vm.getDefaultFilterMessage() },
                },
                [
                  _c(
                    "span",
                    { staticClass: "when-open" },
                    [
                      _c("b-icon", {
                        attrs: { icon: "funnel", "font-scale": "1.5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "span",
                    { staticClass: "when-closed" },
                    [
                      _c("b-icon", {
                        attrs: { icon: "funnel", "font-scale": "1.5" },
                      }),
                    ],
                    1
                  ),
                  _vm._v("\n        Filtra\n      "),
                ]
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "b-col",
            { attrs: { align: "right" } },
            [
              _vm.canVerb(_vm.resource, "store")
                ? _c(
                    "b-button",
                    {
                      staticClass: "btn-create",
                      attrs: {
                        type: "button",
                        variant: "primary",
                        size: "sm",
                        title: "Crea",
                      },
                      on: { click: _vm.onAddBookEntry },
                    },
                    [
                      _c("b-icon", {
                        attrs: { icon: "plus-circle", "aria-hidden": "true" },
                      }),
                      _vm._v("\n        Crea"),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "b-collapse",
        { attrs: { visible: "", id: "collapse-1" } },
        [
          _c(
            "b-form",
            {
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.onSearch(_vm.filterName)
                },
              },
            },
            [
              _c(
                "b-card",
                { staticClass: "filter" },
                [
                  _c(
                    "b-row",
                    [
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _c(
                          "div",
                          [
                            _c(
                              "b-button-toolbar",
                              [
                                _c(
                                  "b-button-group",
                                  [
                                    _c("base-input", {
                                      attrs: {
                                        name: "Anagrafica",
                                        label: "Anagrafica",
                                        readonly: true,
                                      },
                                      model: {
                                        value: _vm.form.inpt_label_registry,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.form,
                                            "inpt_label_registry",
                                            $$v
                                          )
                                        },
                                        expression: "form.inpt_label_registry",
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c(
                                      "b-button",
                                      {
                                        staticClass: "mt-4 btn-quick",
                                        attrs: {
                                          size: "sm",
                                          text: "Button",
                                          variant: "lisaweb",
                                          title: "Ricerca Veloce",
                                        },
                                        on: {
                                          click: _vm.openQuickSearchRegistry,
                                        },
                                      },
                                      [
                                        _c("b-icon", {
                                          attrs: { icon: "search" },
                                        }),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    _vm.form.inpt_label_registry
                                      ? _c(
                                          "b-button",
                                          {
                                            staticClass: "mt-4 btn-quick ml-1",
                                            attrs: {
                                              size: "sm",
                                              text: "Button",
                                              variant: "lisaweb",
                                              title: "Elimina Anagrafica",
                                            },
                                            on: { click: _vm.resetRegistryId },
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "trash" },
                                            }),
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ]),
                      _vm._v(" "),
                      _c("quick-search-registry-modal", {
                        ref: "quickSearchRegistry",
                        on: { input: _vm.handleRegistryModalInput },
                      }),
                      _vm._v(" "),
                      _c("div", { staticClass: "form-group col-md-3" }, [
                        _vm.registry_data
                          ? _c("span", {
                              staticClass: "info",
                              domProps: {
                                innerHTML: _vm._s(
                                  _vm.toInfoData(_vm.registry_data, "registry")
                                ),
                              },
                            })
                          : _vm._e(),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "salesman_id",
                            label: "Produttori",
                            options: _vm.salesmen,
                          },
                          model: {
                            value: _vm.filter.byBroker.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byBroker, "id", $$v)
                            },
                            expression: "filter.byBroker.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "insurer_id",
                            label: "Compagnia",
                            options: _vm.companies,
                          },
                          model: {
                            value: _vm.filter.byInsurer.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byInsurer, "id", $$v)
                            },
                            expression: "filter.byInsurer.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-datepicker", {
                          attrs: { name: "Da", label: "Da" },
                          model: {
                            value: _vm.filter.byCalendar.from,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byCalendar, "from", $$v)
                            },
                            expression: "filter.byCalendar.from",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-datepicker", {
                          attrs: { name: "A", label: "A" },
                          model: {
                            value: _vm.filter.byCalendar.to,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byCalendar, "to", $$v)
                            },
                            expression: "filter.byCalendar.to",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "sectors_id",
                            label: "Comparti",
                            options: _vm.sectors,
                            taggable: false,
                            multiple: true,
                            closeOnSelect: false,
                          },
                          model: {
                            value: _vm.filter.bySector.id,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.bySector, "id", $$v)
                            },
                            expression: "filter.bySector.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-select", {
                          attrs: {
                            name: "various_accounting",
                            label: "Codice Causale",
                            options: _vm.various_accounting_not_system_options,
                            taggable: false,
                            multiple: true,
                            closeOnSelect: false,
                          },
                          model: {
                            value: _vm.filter.byVariousAccounting.id,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.filter.byVariousAccounting,
                                "id",
                                $$v
                              )
                            },
                            expression: "filter.byVariousAccounting.id",
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "col-md-3" },
                      [
                        _c("base-input", {
                          attrs: {
                            name: "tile",
                            label: "Descrizione",
                            placeholder: "Inserisci una descrizione",
                          },
                          model: {
                            value: _vm.filter.byCustom.title.value,
                            callback: function ($$v) {
                              _vm.$set(_vm.filter.byCustom.title, "value", $$v)
                            },
                            expression: "filter.byCustom.title.value",
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c("b-row", [
                    _c(
                      "div",
                      { staticClass: "form-group col-md-3 align-self-end" },
                      [
                        _c(
                          "b-button",
                          {
                            directives: [
                              {
                                name: "b-toggle",
                                rawName: "v-b-toggle:collapse-1",
                                arg: "collapse-1",
                              },
                            ],
                            attrs: {
                              type: "submit",
                              variant: "lisaweb",
                              size: "sm",
                            },
                          },
                          [_vm._v("Cerca")]
                        ),
                        _vm._v(" "),
                        _c(
                          "b-button",
                          {
                            staticClass: "btn-reset",
                            attrs: {
                              type: "button",
                              variant: "lisaweb",
                              size: "sm",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.onClearFilter(_vm.filterName)
                              },
                            },
                          },
                          [_vm._v("Reset")]
                        ),
                      ],
                      1
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("export-options", {
        attrs: {
          exportUrl: _vm.exportUrl,
          repository: _vm.repository,
          resource: _vm.resource,
          tableRef: _vm.$refs[_vm.tableRef],
          filter: _vm.filter,
          options: [
            {
              code: "EXPOVAAC",
              label: null,
              formats: ["csv", "pdf"],
            },
          ],
        },
      }),
      _vm._v(" "),
      _c("book-entries-various-accounting", {
        ref: _vm.tableRef,
        attrs: {
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterOn: {
            byRelations: [
              "byRegistry",
              "byBroker",
              "byVariousAccounting",
              "byTask",
              "byDocument",
            ],
          },
          onlyActions: ["edit", "details", "infomodal", "destroy"],
          filterName: _vm.filterName,
          sortField: "book_date",
          descending: "",
          hasChecksButtonGroup: false,
        },
        on: {
          edit: _vm.onEdit,
          destroy: _vm.onDestroy,
          open_note: _vm.openNoteModal,
          open_document: _vm.openDocumentModal,
        },
      }),
      _vm._v(" "),
      _c("note-detail-modal", { attrs: { items: _vm.notes } }),
      _vm._v(" "),
      _c("document-detail-modal", { attrs: { items: _vm.documents } }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }