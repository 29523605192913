<template>
  <div class="mt-1">
    <b-overlay center :show="isLoading" rounded="sm">
      <div v-if="!isLoading">
        <div v-if="ok">
          <validation-observer
            ref="observer"
            v-slot="{ invalid, handleSubmit }"
          >
            <b-form @submit.prevent="">
              <div class="mt-2">
                <b-card header="Dati Generali" header-tag="header">
                  <b-card-text
                    ><b-row>
                      <div class="form-group col-md-3">
                        <base-input
                          :name="beForm[rep].display.label"
                          vid="display"
                          :label="beForm[rep].display.label"
                          v-model="form[rep].display"
                          placeholder="Inserisci un codice"
                          :rules="getRules('display')"
                          maxlength="3"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-textarea
                          vid="title"
                          :name="beForm[rep].title.label"
                          label="Descrizione"
                          v-model="form[rep].title"
                          placeholder="Inserisci una descrizione"
                          :rules="{ required: true }"
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-select
                          :name="beForm[rep].is_anticipated.label"
                          vid="is_anticipated"
                          label="Acconto"
                          v-model="form[rep].is_anticipated"
                          :options="beForm[rep].is_anticipated.options"
                          :rules="getRules('is_anticipated')"
                        />
                      </div> </b-row
                  ></b-card-text>
                </b-card>
                <b-card
                  header="Codici Di Riferimento"
                  header-tag="header"
                  class="mt-1"
                >
                  <b-card-text>
                    <b-row>
                      <div class="form-group col-md-3">
                        <base-radio
                          vid="status_broker"
                          :name="beForm[rep].status_broker.label"
                          label="Codice produttore"
                          v-model="form[rep].status_broker"
                          :options="beForm[rep].status_broker.options"
                          :rules="getRules('status_broker')"
                          stacked
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-radio
                          vid="status_insurer"
                          :name="beForm[rep].status_insurer.label"
                          label="Codice Compagnia"
                          v-model="form[rep].status_insurer"
                          :options="beForm[rep].status_insurer.options"
                          :rules="getRules('status_insurer')"
                          stacked
                        />
                      </div>
                      <div class="form-group col-md-3">
                        <base-radio
                          vid="status_registry"
                          :name="beForm[rep].status_registry.label"
                          label="Anagrafica Cliente"
                          v-model="form[rep].status_registry"
                          :options="beForm[rep].status_registry.options"
                          :rules="getRules('status_registry')"
                          stacked
                        />
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>

                <b-card
                  :header="config.header"
                  header-tag="header"
                  class="mt-1"
                  v-for="(config, index) in treasuries_config"
                  :key="`config${index}`"
                >
                  <b-card-text>
                    <b-row>
                      <div
                        class="form-group col-md-3"
                        v-for="(treasury, index) in getFilteredTreasuries(
                          treasuries,
                          config.is_system,
                          config.is_abstract,
                          config.is_agency
                        )"
                        :key="index"
                      >
                        <base-radio
                          :vid="`${config.type}-${treasury.value}`"
                          :name="`${config.type}-${treasury.value}`"
                          :label="treasury.text"
                          v-model="treasury.option"
                          :options="
                            beForm['various_accounting'].value_type.options
                          "
                          :rules="getRules('value_type')"
                          stacked
                        />
                      </div>
                      <div
                        class="mx-3"
                        v-if="
                          !getFilteredTreasuries(
                            treasuries,
                            config.is_system,
                            config.is_abstract,
                            config.is_agency
                          ).length
                        "
                      >
                        Nessuna
                      </div>
                    </b-row>
                  </b-card-text>
                </b-card>
              </div>
              <div class="mt-2" v-show="customInputs[rep].length">
                <b-card header="Attributi personalizzati" header-tag="header">
                  <b-card-text>
                    <custom-inputs
                      :customInputs="customInputs[rep]"
                      v-model="form[rep]"
                      :beForm="beForm[rep]"
                      :beRules="beRules[rep]"
                    />
                  </b-card-text>
                </b-card>
              </div>

              <div class="py-2 mb-4">
                <b-button
                  @click="handleSubmit(onSubmit)"
                  type="button"
                  :disabled="invalid"
                  variant="lisaweb"
                  size="sm"
                >
                  Salva
                </b-button>
                <b-button
                  @click="$router.back()"
                  variant="lisaweb"
                  size="sm"
                  class="float-right"
                  ><b-icon-chevron-double-left
                    font-scale="0.9"
                  ></b-icon-chevron-double-left
                  >Torna indietro</b-button
                >
              </div>
            </b-form>
          </validation-observer>
        </div>
        <div v-else>
          {{ errorMessage }}
        </div>
      </div>
      <template #overlay>
        <div class="text-center mt-5">
          <base-icon name="loading" width="35" height="35" />
          <p id="cancel-label">Operazione in corso...</p>
        </div>
      </template>
    </b-overlay>
  </div>
</template>

<script>
import BaseIcon from "@/components/BaseIcon";
import CustomInputs from "@/components/form/CustomInputs";
import FormMixin from "@/mixins/FormMixin";
import ShortcutMixin from "@/mixins/ShortcutMixin";
import BaseInput from "@/components/form/BaseInput";
import BaseSelect from "@/components/form/BaseSelect";
import BaseTextarea from "@/components/form/BaseTextarea.vue";
import { mapGetters } from "vuex";
import BaseRadio from "@/components/form/BaseRadio";
import { getFilteredTreasuries } from "@/utils/accounting";
import { RepositoryFactory } from "@/repositories/RepositoryFactory";

export default {
  mixins: [FormMixin, ShortcutMixin],
  data() {
    return {
      repository: "various_accounting",
      id: this.$route.params.id,
      treasuries: [],
      RECUPERO_ACCONTO: "UC",
      treasuries_config: [
        {
          header: "Comparti di compagnia",
          type: "abstract",
          is_system: "Y",
          is_abstract: "Y",
          is_agency: "N",
        },
        {
          header: "FORME DI PAGAMENTO DI SISTEMA ",
          type: "system",
          is_system: "Y",
          is_abstract: "N",
          is_agency: "N",
        },
        {
          header: "FORME DI PAGAMENTO NON DI SISTEMA ",
          type: "not_system",
          is_system: "N",
          is_abstract: "N",
          is_agency: "N",
        },
      ],
      form: {
        various_accounting: {
          title: null,
          display: null,
          is_anticipated: null,
          status_broker: null,
          status_insurer: null,
          status_registry: null,
          is_system: "N",
        },
        treasury_options: {},
      },
    };
  },
  components: {
    BaseIcon,
    CustomInputs,
    BaseInput,
    BaseSelect,
    BaseTextarea,
    BaseRadio,
  },
  methods: {
    onSubmit() {
      this.addTreasuriesOptionsToForm();
      this.isLoading = true;
      this.update(this.repository, this.id)
        .then((response) => {
          let id = response.data.data.id;
          let promises = [];

          promises.push(this.addTreasuries(id));
          Promise.all(promises)
            .then(() => {
              this.isLoading = false;

              this.$showSnackbar({
                preset: "success",
                text: `Azione Completata: Movimento Vario Modificato`,
              });
              this.shortcut(
                "various_accountings.index",
                null,
                null,
                "filterVariousAccountings"
              );
            })
            .catch((error) => {
              let errMsg = this.$getErrorMessage(error);
              this.$showSnackbar({
                preset: "error",
                text: `${errMsg}`,
              });
              this.isLoading = false;
            });
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    },
    addTreasuries(id) {
      const Repo = RepositoryFactory.get(this.repository);
      return Repo.pivot_update(id, "treasury", {
        treasury: this.form["treasury_options"],
      });
    },
    addTreasuriesOptionsToForm() {
      for (let i = 0; i < this.treasuries.length; i++) {
        this.form["treasury_options"][this.treasuries[i].id] = {
          value_type: this.treasuries[i].option,
        };
      }
      delete this.form[this.rep]["value_type"];
    },
    setTreasuryText(sectors, treasury) {
      const sector = sectors.find(
        (sector) => sector.value === treasury?.sector?.id
      );
      console.log("treasury?.text", treasury?.text);
      console.log("treasury?.title", treasury?.title);
      console.log("sector?.text", sector?.text);
      console.log("final text", treasury?.text ?? treasury?.title);
      const hasText = treasury?.text ? true : false;
      const text = treasury?.text ?? treasury?.title;
      if (
        sector?.is_system === "N" &&
        text.indexOf(`(${sector?.text})`) < 0 &&
        text.indexOf(`↪`) < 0
      ) {
        return `${
          hasText ? treasury.text : treasury.code + " - " + treasury.title
        }
          ↪ collegata al comparto <strong>${
            sector?.text ? " " + sector.text : ""
          }<strong>`;
      }
      return `${
        hasText ? treasury.text : treasury.code + " - " + treasury.title
      }`;
    },
    getFilteredTreasuries,
    ...mapGetters("auth", {
      getAllTreasuries: "allTreasuries",
      getSectors: "sectors",
    }),
  },
  created() {
    this.fetchEditForm(this.repository, this.id).then(() => {
      const Repo = RepositoryFactory.get("various_accounting");
      //let querystring = ``;
      return Repo.show(this.id)
        .then((response) => {
          console.log("Response: ", response.data.data);
          let sectors = this.getSectors();
          //Add associated treasuries
          if (response.data.data.treasuries) {
            const associatedTreasuries = response.data.data.treasuries.map(
              (treasury) => {
                return {
                  option: treasury.pivot.value_type,
                  id: treasury.id,
                  text: this.setTreasuryText(sectors, treasury),
                  treasury: {
                    sector: {
                      is_abstract: {
                        value: sectors.find(
                          (sector) => sector.value === treasury.sector_id
                        ).is_abstract,
                      },
                      is_system: {
                        value: sectors.find(
                          (sector) => sector.value === treasury.sector_id
                        ).is_system,
                      },
                      is_agency: {
                        value: sectors.find(
                          (sector) => sector.value === treasury.sector_id
                        ).is_agency,
                      },
                    },
                  },
                };
              }
            );

            // Associated treasury ids
            const associatedTreasuriesIds = response.data.data.treasuries
              .filter((treasury) => treasury.id)
              .map((treasury) => treasury.id);
            // Missing treasury ids
            const missingTreasuries = this.getAllTreasuries().filter(
              (treasury) =>
                !associatedTreasuriesIds.includes(treasury.value) &&
                treasury.code !== this.RECUPERO_ACCONTO
            );
            // Add missing treasuries
            const parsedMissingTreasuries = missingTreasuries.map(
              (treasury) => {
                return {
                  option: 0,
                  id: treasury.value,
                  text: this.setTreasuryText(sectors, treasury),
                  treasury: {
                    sector: treasury.sector,
                  },
                };
              }
            );
            // Merge missing treasuries
            this.treasuries = [
              ...associatedTreasuries,
              ...parsedMissingTreasuries,
            ];
          }
          this.isLoading = false;
        })
        .catch((error) => {
          let errMsg = this.$getErrorMessage(error);
          this.$showSnackbar({
            preset: "error",
            text: `${errMsg}`,
          });
          this.isLoading = false;
        });
    });
  },
  computed: {
    rep() {
      return this.repository;
    },
  },
};
</script>
