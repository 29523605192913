var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.data.registries.length > 0 || _vm.data.registry_id
    ? _c(
        "b-card",
        { attrs: { header: "true", "header-tag": "header" } },
        [
          _c(
            "div",
            {
              staticClass: "d-flex justify-content-between align-items-center",
              attrs: { slot: "header" },
              slot: "header",
            },
            [
              _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
                _c("b", [_vm._v(_vm._s(_vm.header))]),
              ]),
            ]
          ),
          _vm._v(" "),
          _vm._l(_vm.fields, function (field, index) {
            return _c("b-row", { key: index }, [
              _vm.statusRegistryField(field)
                ? _c("div", { staticClass: "col-md-4 border-bottom" }, [
                    _vm._v("\n      " + _vm._s(field.label) + "\n    "),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.statusRegistryField(field)
                ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                    _vm._v(
                      "\n      " + _vm._s(_vm.text(field.value)) + "\n    "
                    ),
                  ])
                : _vm._e(),
            ])
          }),
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }