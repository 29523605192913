var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "b-card",
    { attrs: { header: "true", "header-tag": "header" } },
    [
      _c(
        "div",
        {
          staticClass: "d-flex justify-content-between align-items-center",
          attrs: { slot: "header" },
          slot: "header",
        },
        [
          _c("p", { staticClass: "mb-0 d-inline-block align-middle" }, [
            _c("b", [_vm._v(_vm._s(_vm.header))]),
          ]),
        ]
      ),
      _vm._v(" "),
      _vm._l(_vm.data.entry_details, function (entry_detail, index) {
        return _c(
          "div",
          { key: index },
          [
            entry_detail.gross !== 0
              ? _c("b-row", [
                  _vm.data.entry_details.length
                    ? _c("div", { staticClass: "col-md-4 border-bottom" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(entry_detail.treasury.title) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.data.entry_details.length
                    ? _c("div", { staticClass: "col-md-8 border-bottom" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.toLocaleCurrency(entry_detail.gross)) +
                            "\n      "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }