var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "mx-1" },
    [
      _vm.formLoaded
        ? _c(
            "b-row",
            [
              _c(
                "b-col",
                { attrs: { align: "right" } },
                [
                  _vm.canVerb(_vm.resource, "store")
                    ? _c(
                        "b-button",
                        {
                          attrs: {
                            type: "button",
                            variant: "primary",
                            size: "sm",
                            title: "Crea",
                          },
                          on: {
                            click: function ($event) {
                              return _vm.createTask(_vm.task_type)
                            },
                          },
                        },
                        [
                          _c("b-icon", {
                            attrs: {
                              icon: "plus-circle",
                              "aria-hidden": "true",
                            },
                          }),
                          _vm._v("\n        Crea"),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c("table-task", {
        ref: _vm.tableRef,
        attrs: {
          fields: _vm.fields,
          repository: _vm.repository,
          resource: _vm.resource,
          filterName: _vm.filterName,
          onlyActions: ["edit", "destroy"],
          noInnerWidth: "",
        },
        on: { edit: _vm.onEdit, destroy: _vm.destroyEvent },
      }),
      _vm._v(" "),
      _vm.formLoaded
        ? _c(
            "div",
            [
              _c("event-create-modal", {
                attrs: {
                  beFormCreate: _vm.beCreateForm["task"],
                  beRulesCreate: _vm.beRules["task"],
                },
                on: {
                  cancel: _vm.cancelEventCreation,
                  create: _vm.storeEvent,
                  addAppointment: _vm.onAddAppointment,
                },
                model: {
                  value: _vm.evt,
                  callback: function ($$v) {
                    _vm.evt = $$v
                  },
                  expression: "evt",
                },
              }),
              _vm._v(" "),
              _c("event-change-modal", {
                attrs: {
                  beFormUpdate: _vm.beEditForm["task"],
                  beRulesUpdate: _vm.beRules["task"],
                },
                on: {
                  cancel: _vm.cancelEventChange,
                  update: function ($event) {
                    return _vm.putEvent({ id: _vm.task_id })
                  },
                },
                model: {
                  value: _vm.evt,
                  callback: function ($$v) {
                    _vm.evt = $$v
                  },
                  expression: "evt",
                },
              }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }