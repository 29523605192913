import { render, staticRenderFns } from "./BookEntriesVariousAccounting.vue?vue&type=template&id=67073063&"
import script from "./BookEntriesVariousAccounting.vue?vue&type=script&lang=js&"
export * from "./BookEntriesVariousAccounting.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/app/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('67073063')) {
      api.createRecord('67073063', component.options)
    } else {
      api.reload('67073063', component.options)
    }
    module.hot.accept("./BookEntriesVariousAccounting.vue?vue&type=template&id=67073063&", function () {
      api.rerender('67073063', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/tables/BookEntriesVariousAccounting.vue"
export default component.exports